import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Loader from "./components/Loader";

const Login = lazy(() => import('./components/Login'))
const Payments = lazy(() => import('./components/Payments'))
const PrivateRoutes = lazy(() => import('./components/PrivateRoutes'))

function App() {  
  return (
    <Router>
      <Suspense fallback={ <Loader /> }>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/payments" element={<Payments />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App;
